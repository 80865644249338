<template>
  <div ref="verifyRef" class="sliderContent" onselectstart="return false;">
    <div v-loading="slideImgLoading" class="slide-img-box" :style="`width: ${width}px; height: auto; position: relative`">
      <canvas id="main" :width="width" :height="height"></canvas>
      <canvas id="main-block" class="slider" :width="width" :height="height" :style="{left: `${sliderLeft}px`}"></canvas>
      <div class="icon-box" @click="reset">
        <i class="img-icon iconfont icon-cloud-refresh"></i>
      </div>
    </div>
    <div class="move-box" :style="`width: ${width}px`">
      <div class="drag-box" :class="sliderLeftSide ? 'left-success-bg' : 'left-error-bg'" :style="`width: ${moveLeft}px`">
        <div
          class="move-slider"
          :class="sliderLeftSide ? 'left-success-slide' : 'left-error-slide'"
          :style="`left: ${moveLeft}px`"
          @mousedown="moveBall"
          @touchstart="touchStartEvent($event, true)"
        >
          <span class="iconfont icon-youjiantou"></span>
        </div>
      </div>
      <div class="drag-text">Please swipe right to complete verification</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideVerify',
  props: {
    width: {
      type: Number,
      default: 420
    }
  },
  data() {
    return {
      height: 200,
      imgList: [
        '/code/1.jpg',
        '/code/2.jpg',
        '/code/3.jpg',
        '/code/4.jpg',
        '/code/5.jpg',
        '/code/6.jpg',
        '/code/7.jpg',
        '/code/8.jpg',
        '/code/9.jpg',
        '/code/10.jpg',
        '/code/11.jpg',
        '/code/12.jpg',
        '/code/13.jpg',
        '/code/14.jpg',
        '/code/15.jpg'
      ],
      imgSrc: '',
      x: 0,
      y: 0,
      PI: Math.PI,
      sliderLeft: 0,
      r: 6,
      w: 40,
      dom: null,
      moveLeft: 0,
      beginX: 0,
      bg: null,
      block: null,
      sliderLeftSide: true,
      slideImgLoading: false
    }
  },
  mounted() {
    this.imgSrc = this.getImage()
    this.loadImage()
  },
  methods: {
    // 创建底部画布
    loadImage() {
      const mainDom = document.getElementById('main')
      this.bg = mainDom.getContext('2d')
      const imgSrc = this.imgSrc
      const img = document.createElement('img')
      img.src = imgSrc
      img.onload = () => {
        mainDom.height = (img.height / img.width) * mainDom.width
        this.bg.drawImage(img, 0, 0, mainDom.width, mainDom.height)
        this.drawHandle(this.bg, this.PI, mainDom.height)
      }
    },
    // 底部画布绘图
    drawHandle(ctx, PI, height) {
      const r = 6
      const w = 40
      const x = Math.round(Math.random() * (this.width - 150) + 100)
      const y = Math.round(Math.random() * (height - 60) + 0)
      this.x = x
      this.y = y
      // 绘制
      ctx.lineWidth = 1
      ctx.strokeStyle = 'white'
      ctx.beginPath()
      ctx.moveTo(x, y)
      // top
      ctx.arc(x + w / 2, y, r, -PI, 0, true)
      ctx.lineTo(x + w, y)
      // right
      ctx.arc(x + w, y + w / 2, r, 1.5 * PI, 0.5 * PI, false)
      ctx.lineTo(x + w, y + w)
      // bottom
      ctx.arc(x + w / 2, y + w, r, 0, PI, false)
      ctx.lineTo(x, y + w)
      // left
      ctx.arc(x, y + w / 2, r, 0.5 * PI, 1.5 * PI, true)
      ctx.lineTo(x, y)
      ctx.stroke()
      ctx.fillStyle = 'rgba(0, 0, 0, 0.8)' // 设置背景颜色
      ctx.fill()
      this.loadBlock(false)
    },
    // 创建画布滑块
    loadBlock() {
      const blockDom = document.getElementById('main-block')
      this.block = blockDom.getContext('2d')
      const img = document.createElement('img')
      img.src = this.imgSrc
      img.onload = () => {
        this.blockHandle(this.block, img)
      }
    },
    // 画布滑块绘图
    blockHandle(ctx, img) {
      const r = 6
      const w = 40
      const x = this.sliderLeft
      const y = this.y
      const PI = this.PI
      ctx.lineWidth = 1
      // 绘制
      // ctx.beginPath();
      // left
      ctx.moveTo(x, y)
      // top
      ctx.arc(x + w / 2, y, r, -PI, 0, true)
      ctx.lineTo(x + w, y)
      // right
      ctx.arc(x + w, y + w / 2, r, 1.5 * PI, 0.5 * PI, false)
      ctx.lineTo(x + w, y + w)
      // bottom
      ctx.arc(x + w / 2, y + w, r, 0, PI, false)
      ctx.lineTo(x, y + w)
      // left
      ctx.arc(x, y + w / 2, r, 0.5 * PI, 1.5 * PI, true)
      ctx.lineTo(x, y)
      ctx.shadowBlur = 20
      ctx.shadowColor = 'white'
      ctx.stroke()
      ctx.clip()
      ctx.drawImage(img, -this.x + this.sliderLeft, 0)
    },
    // 滑块移动
    moveBall(e) {
      e.preventDefault()
      this.beginX = e.pageX
      this.dom = document.getElementsByTagName('html')[0]
      this.dom.onmousemove = (e) => {
        var x = e.pageX - this.beginX
        if (x >= 0 && x <= (this.width - 50)) {
          this.moveLeft = x
          this.sliderLeft = x
        } else {
          this.moveLeft = this.width - 50
          this.sliderLeft = this.width - 50
        }
        this.sliderLeftSide = true
      }
      this.leaveBall()
    },
    // 滑块离开
    leaveBall() {
      this.dom.onmouseup = (e) => {
        this.dom.onmousemove = null
        var x = e.pageX - this.beginX
        if ((x - this.x) >= -3 && (x - this.x) <= 3) {
          this.sliderLeftSide = true
          this.$emit('onSuccess')
        } else {
          this.sliderLeftSide = false
          document.getElementsByClassName('move-slider')[0] && (document.getElementsByClassName('move-slider')[0].style.backgroundColor = '#f57a7a')
          const timer = setTimeout(() => {
            setTimeout(() => {
              this.moveLeft = 0
              this.sliderLeft = 0
              this.reset()
              document.getElementsByClassName('left-error-slide')[0] && (document.getElementsByClassName('left-error-slide')[0].style.backgroundColor = '#0E5FAC')
              document.querySelector('.move-slider') && document.querySelector('.move-slider').classList.remove('left-error-slide')
            }, 1000)
            clearTimeout(timer)
          }, 500)
        }
      }
    },
    // 移动端滑块滑动
    touchStartEvent(e, start) {
      if (start) {
        this.beginX = e.changedTouches[0].clientX
        this.dom = document.getElementsByTagName('html')[0]
        this.dom.ontouchmove = (e) => {
          var x = e.changedTouches[0].clientX - this.beginX
          if (x >= 0 && x <= (this.width - 50)) {
            this.moveLeft = x
            this.sliderLeft = x
          } else {
            this.moveLeft = this.width - 50
            this.sliderLeft = this.width - 50
          }
          this.sliderLeftSide = true
        }
        this.leaveBallMobile()
      }
    },
    // 移动端滑块离开
    leaveBallMobile() {
      this.dom.ontouchend = (e) => {
        this.dom.ontouchmove = null
        var x = e.changedTouches[0].clientX - this.beginX
        if ((x - this.x) >= -3 && (x - this.x) <= 3) {
          this.sliderLeftSide = true
          this.$emit('onSuccess')
        } else {
          this.sliderLeftSide = false
          document.getElementsByClassName('move-slider')[0] && (document.getElementsByClassName('move-slider')[0].style.backgroundColor = '#f57a7a')
          const timer = setTimeout(() => {
            setTimeout(() => {
              this.moveLeft = 0
              this.sliderLeft = 0
              this.reset()
              document.getElementsByClassName('left-error-slide')[0] && (document.getElementsByClassName('left-error-slide')[0].style.backgroundColor = '#0E5FAC')
              document.querySelector('.move-slider') && document.querySelector('.move-slider').classList.remove('left-error-slide')
            }, 1000)
            clearTimeout(timer)
          })
        }
      }
    },
    // 随机图片
    getImage() {
      const num = Math.round(Math.random() * (15 - 2) + 1)
      if (num) {
        this.slideImgLoading = false
        return this.imgList[num]
      } else {
        this.slideImgLoading = true
      }
    },
    // 刷新
    reset() {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="less" scoped>
.sliderContent {
  .slide-img-box {
    position: relative;
    .icon-box {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      width: 40px;
      height: 40px;
      .img-icon {
        color: #fff;
        font-size: 30px;
      }
    }
    .slider {
      position: absolute;
    }
  }
  .move-box {
    margin-top: 8px;
    height: 36px;
    background: #F5F5F5;
    border: 1px solid #DADADA;
    position: relative;
    .drag-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 56px;
      background-color: #0E5FAC;
      border-radius: 4px;
      &.left-success-bg {
        height: 34px;
        background-color: rgba(209, 233, 254, 1);
        border-color: #0E5FAC;
        z-index: 99;
        border-radius: 0;
      }
      &.left-error-bg {
        height: 34px;
        background-color: #fce1e1;
        z-index: 99;
        border-radius: 0;
      }
    }
    .drag-text {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      padding: 0 6px 0 42px;
    }
    .move-slider {
      width: 40px;
      height: 34px;
      background-color: #0E5FAC;
      position: absolute;
      top: 0;
      z-index: 99;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
      &.left-success-slide{
        background-color: #0E5FAC;
      }
      &.left-error-slide{
        background-color: #fce1e1;
      }
      .iconfont {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .move-box {
      .drag-text {
        font-size: 12px;
      }
    }
  }
}
</style>

<template>
  <div class="slide-dialog">
    <el-dialog
      custom-class="change-email-dialog"
      title=""
      :visible.sync="email_visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="15%"
      @close="close"
    >
      <div class="slide-verify-box">
        <slide-verify :key="slideId" :width="slideWidth" @reset="reset" @onSuccess="onSuccess" />
      </div>
    </el-dialog>
    <el-dialog
      custom-class="change-email-dialog email-error-dialog"
      title=""
      :visible.sync="email_error_visible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      top="15%"
    >
      <div class="email-error-box">
        <span class="iconfont" :class="emailErrorText === LP.lang_verification_successful_email ? 'icon-yes success-icon' : 'icon-cuowu'"></span>
        <p v-html="emailErrorText"></p>
      </div>
      <div slot="footer" class="change-email-dialog-footer" :class="emailErrorText === LP.lang_verification_successful_email ? 'success-footer' : ''">
        <el-button type="primary" @click="emailErrorClose">{{ LP.lang_confirm }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SlideVerify from '@/components/public/slideVerify.vue'
export default {
  components: {
    SlideVerify
  },
  props: {
    emailDialogVisible: {
      type: Boolean,
      default: false
    },
    emailErrorDialogVisible: {
      type: Boolean,
      default: false
    },
    emailErrorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      slideId: +new Date(),
      slideWidth: 420,
      email_visible: false,
      email_error_visible: false
    }
  },
  watch: {
    emailDialogVisible(val, oldVal) {
      if (val !== oldVal) {
        this.email_visible = val
      }
      if (val) {
        this.reset()
      }
    },
    emailErrorDialogVisible(val, oldVal) {
      if (val !== oldVal) {
        this.email_error_visible = val
      }
    },
    immediate: true,
    deep: true
  },
  mounted: function() {
    // 监听页面宽度变化
    window.onresize = () => {
      if (document.body.clientWidth <= 480) {
        this.slideWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 64
      }
    }
    this.$nextTick(function() {
      if (document.body.clientWidth <= 480) {
        this.slideWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 64
      }
    })
  },
  methods: {
    // 刷新
    reset() {
      this.slideId = +new Date()
    },
    // 验证成功
    onSuccess() {
      this.$emit('emailSuceess')
    },
    close() {
      this.$emit('closeDialog')
    },
    emailErrorClose() {
      this.$emit('closeEmailError')
    }
  }
}
</script>

<style lang="less" scoped>
.slide-dialog {
  .slide-verify-box {
    position: relative;
    .slide-verify-refresh {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      .iconfont {
        color: #fff;
        font-size: 28px;
      }
    }
  }
  /deep/ .change-email-dialog {
    width: 100% !important;
    max-width: 480px;
    font-size: 14px;
    .el-dialog__header {
      padding: 20px 30px 0 30px !important;
    }
    .el-dialog__body {
      padding: 20px 30px !important;
      min-height: 120px !important;
    }
    .email-error-box {
      display: flex;
      .iconfont {
        color: red;
        font-size: 20px;
        margin-right: 6px;
      }
      .success-icon {
        color: #21A13B;
      }
    }
    .success-footer {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    /deep/ .change-email-dialog {
      width: calc(~"100% - 32px") !important;
      .el-dialog__header {
        padding: 20px 16px 0 16px !important;
      }
      .el-dialog__body {
        padding: 20px 16px !important;
      }
    }
    .slide-verify-slider-text {
      font-size: 12px;
      padding-left: 15px;
    }
  }
}
</style>
